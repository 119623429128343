<template>
    <v-container>
        <v-row>
            <v-col cols="6" md="3" lg="2" :key="image.src" v-for="image in filteredImages" class="text-center">
                <v-lazy :height="imgSize">
                    <v-img :src="picDir + image.src" :alt="image.title" :height="imgSize" :width="imgSize" cover class="mx-auto grey lighten-4"/>
                </v-lazy>
                <div class="text-center font-weight-normal grey--text">{{ image.title }}</div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
const path = require('path')

export default {
    name: 'MelonPics',
    props: {
        alternate: {type: Boolean, default: false},
        search: {type: String, default: ''}
    },
    data() {
        return {
            images: [],
            imgSize: 192
        }
    },
    mounted() {
        this.importAll(require.context('../../public/images/team/', true, /\.jpg$/));
    },

    methods: {
        toTitleCase(str) {
            return str.replace(
                /\w\S*/g,
                function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        nicename(name) {

            if (name.indexOf('_') >= 0) {
                let parts = name.split('_');
                return this.toTitleCase(parts[1]) + ' ' + this.toTitleCase(parts[0]);
            }

            let lowerName = name.toLowerCase();
            let initial = lowerName.substring(lowerName.length - 1, lowerName.length);
            let surname = lowerName.substr(0, name.length - 1);
            return initial.toUpperCase() + '. ' + surname.charAt(0).toUpperCase() + surname.slice(1);
        },
        importAll(dirList) {
            this.images = [];
            dirList.keys().forEach(key => {
                this.images.push({src: key, title: this.nicename(path.basename(key, '.jpg'))})
            });

        },
    },
    computed: {
        lowerSearch() {
            return (this.search ?? '').toLowerCase();
        },
        filteredImages() {
            console.log(this.lowerSearch);
            return this.images.filter(el => this.lowerSearch === '' || el.title.toLowerCase().indexOf(this.lowerSearch) >= 0)
        },
        picDir() {
            return this.alternate ? "./images/alt/" : "./images/team/";
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
