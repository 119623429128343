<template>
    <v-app>
        <v-app-bar
            app
            color="primary"
            dark
        >
            <div class="headline hidden-sm-and-down"> Melon Pics</div>
            <v-spacer></v-spacer>
            <v-row class="shrink">
                <v-col class="search-box">
                    <v-text-field prepend-icon="mdi-magnify" label="" placeholder="search" clearable hide-details v-model="search" />
                </v-col>
                <v-col class="shrink">
                    <v-switch hide-details v-model="alternate" label="Alt"></v-switch>
                </v-col>
            </v-row>

        </v-app-bar>

        <v-main>
            <v-row class="mt-4">
                <v-col class=" hidden-md-and-up">
                    <div class="headline mx-4">Melon Pics</div>
                </v-col>

            </v-row>
            <melon-pics :search="search" :alternate="alternate"/>
        </v-main>
    </v-app>
</template>

<script>
import MelonPics from './components/MelonPics.vue'

export default {
    name: 'App',
    data() {
        return {
            alternate: false,
            search: '',
        }
    },
    components: {
        MelonPics
    }
}
</script>

<style>
.search-box {
    max-width: 500px;
}
</style>
